<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="panel">
            <div class="panel-heading">
              <h2 class="pageTitle">
                登録完了<span>complete</span>
              </h2>
            </div>
            <div class="panel-body">
              <div class="row mb30">
                <div class="col-sm-12 center completeM">
                  <svg id="compmark" class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                  Complete!<br>
                  <span>登録が完了しました。下記よりログインをお願いします。</span>
                </div>
              </div>
              <ul class="actionVertical clearfix">
                <li>
                  <router-link :to="{name: 'login'}" class="btn btn01">
                    ログイン画面へ
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'RegisterSuccess'
}
</script>

<style scoped>

</style>
